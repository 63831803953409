import { useDispatch } from "react-redux";
import { useEffect } from "react";

export default function Logout()
{
    const dispatch = useDispatch();

    useEffect(()=>{
        dispatch({"type" : "LOGOUT"});
        window.location.replace("https://amtasks.somee.com/");
    }, [])
    return null
}