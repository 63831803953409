import { Link, useNavigate } from "react-router-dom"
export default function SideBar(props)
{
    return(
        <aside id="left-panel" className="left-panel" style={props.style}>
            <nav className="navbar navbar-expand-sm navbar-default">
                <div id="main-menu" className="main-menu collapse navbar-collapse">
                    <ul className="nav navbar-nav">
                        <li className={(props.active == "dashboard")?"active":""}>
                            <Link to="/dashboard"><i className="menu-icon fa fa-laptop"></i>Dashboard </Link>
                        </li>
                        <li className={(props.active == "users")?"active":""}>
                            <Link to="/users"><i className="menu-icon fa fa-users"></i>Companies </Link>
                        </li>
                        <li className={(props.active == "profile")?"active":""}>
                            <Link to="/profile"><i className="menu-icon fa fa-user"></i>Profile </Link>
                        </li>
                        <li className={(props.active == "logout")?"active":""}>
                            <Link to="/logout"><i className="menu-icon fa fa-power-off"></i>Logout </Link>
                        </li>
                    </ul>
                </div>
            </nav>
        </aside>
    )
}