import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {isAuthenticated} from "../Functions/Authenticate";
import SideBar from "./Sub-Components/SideBar";
import Header from "./Sub-Components/Header";
import Footer from "./Sub-Components/Footer";
import Swal from "sweetalert2";
import axios from "axios";

export default function Profile()
{
    const  nav = useNavigate();
    const [tog, setTog] = useState({"display":"block"});

    const handleIconClick = () =>{
        let width = window.innerWidth;
        if (width < 1024) {
            if(tog.display === "block")
            {
                setTog({...tog,"display": "none"})
            }
            else
            {
                setTog({...tog,"display": "block"})
            }
        } else {
            document.body.classList.item("open") ? document.body.classList.remove("open") : document.body.classList.add("open")
        }
    }

    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [password, setPassword] = useState("");

    useEffect(()=>{
        if(!isAuthenticated())
        {
            nav("/");
        }

        getProfile()
    },[]);

    const handleInputChange = (e) => {
        if(e.target.name == "firstName")
        {
            setFirstName(e.target.value);
        }
        else if(e.target.name == "lastName")
        {
            setLastName(e.target.value);
        }
        else if(e.target.name == "phone")
        {
            setPhone(e.target.value);
        }
        else if(e.target.name == "password")
        {
            setPassword(e.target.value);
        }
    }

    const resetForm = (e) => {
        e.preventDefault();
        setFirstName("");
        setLastName("");
        setPassword("");
        setPhone("")

        getProfile()
    }

    const frmSubmit = async (e) => {
        e.preventDefault();
        try
        {
            Swal.fire({
                title: 'Loading...',
                text: 'Fetching company data.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            })
            const json = {
                "id": 0,
                "roleId": 0,
                "firstName": firstName,
                "lastName": lastName,
                "phone": phone
            }
            if(password != null && password != "")
            {
                json["password"] = password
            }
            let data = JSON.stringify(json);
              
            let config = {
                method: 'post',
                maxBodyLength: Infinity,
                url: 'https://amtasksapi.somee.com/Admin/ProfileUpdate',
                headers: { 
                  'Authorization': localStorage.getItem("token"), 
                  'Content-Type': 'application/json'
                },
                data : data
            };
            let response  = await axios.request(config)

            if(response.data.success)
            {
                Swal.close()
                Swal.fire({
                    icon: "success",
                    title: "Updated",
                    text: "Profile Updated Successfully"
                }).then(function(){
                    nav("/dashboard");
                })   
            }
        }
        catch(error)
        {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong while fetching data!',
            });
        }
    }

    const getProfile = async () =>{
        try
        {
            Swal.fire({
                title: 'Loading...',
                text: 'Fetching company data.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            })

            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://amtasksapi.somee.com/Admin/GetProfile',
                headers: { 
                  'Authorization': localStorage.getItem("token")
                }
            }
            let response = await axios.request(config);
            setFirstName(response.data.firstName)
            setLastName(response.data.lastName)
            setPhone(response.data.phone)
            Swal.close()
        }
        catch(error)
        {
            Swal.close()
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong while fetching data!',
            });
        }
    }


    return (
        <>
            <SideBar style={tog} active="profile" />
            <div id="right-panel" className="right-panel">
                <Header onIconClick={handleIconClick} />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="box-title">Super Admin Profile </h4>
                                    </div>
                                    <div className="card-body">
                                        <form name="form" onSubmit={(e) => {frmSubmit(e)}} action="#" method="post" class="form-horizontal">
                                            <div class="card">
                                                <div class="card-body card-block">
                                                        <div class="row form-group">
                                                            <div class="col col-md-3"><label for="firstName" class=" form-control-label">First Name</label></div>
                                                            <div class="col-12 col-md-9"><input required={true} value={firstName} onChange={handleInputChange} type="text" id="firstName" name="firstName" placeholder="Enter First Name..." class="form-control"/></div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <div class="col col-md-3"><label for="lastName" class=" form-control-label">Last Name</label></div>
                                                            <div class="col-12 col-md-9"><input required={true} value={lastName} onChange={handleInputChange} type="text" id="lastName" name="lastName" placeholder="Enter Last Name..." class="form-control"/></div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <div class="col col-md-3"><label for="phone" class=" form-control-label">Phone</label></div>
                                                            <div class="col-12 col-md-9"><input required={true} value={phone} onChange={handleInputChange} type="number" id="phone" name="phone" placeholder="Enter Phone Number..." minLength={10} maxLength={10} class="form-control"/></div>
                                                        </div>
                                                        <div class="row form-group">
                                                            <div class="col col-md-3"><label for="password" class=" form-control-label">Password</label></div>
                                                            <div class="col-12 col-md-9"><input value={password} onChange={handleInputChange} type="password" id="password" name="password" placeholder="Enter Password..." class="form-control"/></div>
                                                        </div>
                                                </div>
                                                
                                                <div class="card-footer">
                                                    <button name="btnSubmit" type="submit" class="btn btn-primary btn-sm">
                                                        <i class="fa fa-dot-circle-o"></i> Submit
                                                    </button>
                                                    <button onClick={resetForm} type="reset" class="btn btn-danger btn-sm">
                                                        <i class="fa fa-ban"></i> Reset
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}