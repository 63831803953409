const initialState = {
    name: null,
    token: null
};

const token = localStorage.getItem("token");
if (token) {
    initialState.token = token;
    initialState.name = localStorage.getItem("name");
}

export const rootReducer = (state = initialState, action) => {
    switch(action.type){
        case "LOGIN":
            localStorage.setItem('token', action.payload.token);
            localStorage.setItem('name', action.payload.name);
            return {
                ...state,
                name: action.payload.name,
                token: action.payload.token
            };
        case "LOGOUT":
            localStorage.clear();
            return {
                ...state,
                name: null,
                token: null
            };
        default:
            return state;
    }
};