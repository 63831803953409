import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import { useEffect } from "react"
export default function Header(props)
{
    let name = useSelector(user => user.name);
    return(
        <header id="header" className="header">
            <div className="top-left">
                <div className="navbar-header">
                    <Link className="navbar-brand" style={{color:"#ac26ab", fontWeight:"bolder", fontSize:"xx-large"}} to="/dashboard">
                        AM Tasks
                    </Link>
                    <Link className="navbar-brand hidden" style={{color:"#ac26ab"}} to="/dashboard">
                        AM Tasks
                    </Link>
                    <a id="menuToggle" onClick={props.onIconClick} className="menutoggle"><i className="fa fa-bars"></i></a>
                </div>
            </div>
            <div class="top-right">
                <div class="header-menu">
                    <div class="user-area dropdown float-right">
                        <a href="#" class="dropdown-toggle active" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img class="user-avatar rounded-circle" src="/images/admin.jpg" alt="User Avatar" />
                        </a>

                        <div class="user-menu dropdown-menu">
                            <a class="nav-link">Name : {name}</a>
                            <Link class="nav-link" to="/profile"><i class="fa fa- user"></i>My Profile</Link>
                            <Link class="nav-link" to="/logout"><i class="fa fa-power -off"></i>Logout</Link>
                        </div>
                    </div>

                </div>
            </div>
        </header>
    )
}