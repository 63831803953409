export default function Footer()
{
    const year = new Date().getFullYear();
    return(
        <>
            <div className="clearfix"></div>
            <footer className="site-footer">
                <div className="footer-inner bg-white">
                    <div className="row">
                        <div className="col-sm-6">
                            Copyright &copy; {year} MSquare Technologies
                        </div>
                        <div className="col-sm-6 text-right">
                            Designed by MSquare Technologies
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}