import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated } from "../Functions/Authenticate";
import SideBar from "./Sub-Components/SideBar";
import Header from "./Sub-Components/Header";
import Footer from "./Sub-Components/Footer";
import CustomTable from "./Sub-Components/CustomTable";
import axios from 'axios';
import Swal from "sweetalert2";

export default function Users() {
    const columns = [
        {
            name: "Company",
            sortable: true,
            selector: row => row.name
        },
        {
            name: "Registered On",
            sortable: true,
            selector: row => row.registerOn
        },
        {
            name: "Total Projects",
            sortable: true,
            selector: row => row.totalProjects
        },
        {
            name: "Total Users",
            sortable: true,
            selector: row => row.totalUsers
        },
        {
            name: "Status",
            sortable: true,
            selector: row => row.status
        },
        {
            name: "Action",
            sortable: false,
            selector: row => row.action
        }
    ];

    const [data, setData] = useState([]);
    const nav = useNavigate();
    const [tog, setTog] = useState({ "display": "block" });

    const handleIconClick = () => {
        let width = window.innerWidth;
        if (width < 1024) {
            if (tog.display === "block") {
                setTog({ ...tog, "display": "none" });
            } else {
                setTog({ ...tog, "display": "block" });
            }
        } else {
            document.body.classList.toggle("open");
        }
    };

    const getCompanies = async () => {
        try {
            Swal.fire({
                title: 'Loading...',
                text: 'Fetching company data.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            })
            let config = {
                method: "GET",
                maxBodyLength: Infinity,
                url: "https://amtasksapi.somee.com/Admin/GetCompanies",
                headers: {
                    "Authorization": localStorage.getItem("token")
                }
            };
            const response = await axios.request(config);
            const companies = response.data.companies["$values"].map(comp => ({
                id: comp.id,
                name: comp.name,
                registerOn: comp.createdOn,
                totalProjects: comp.totalProjects,
                totalUsers: comp.totalMembers,
                status: ((comp.status == "active")?<span className="badge badge-success">Active</span>:<span className="badge badge-danger">Block</span>),
                action: ((comp.status == "block")?<button onClick={() => handleStatusChange(comp.id, "active")} className="btn btn-sm btn-outline-success">Make Active</button>:<button onClick={() => handleStatusChange(comp.id, "block")} className="btn btn-sm btn-outline-danger">Block Company</button>)
            }));
            setData(companies);
            Swal.close();
        } catch (error) {
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong while fetching data!',
            });
        }
    };

    useEffect(() => {
        if (!isAuthenticated()) {
            nav("/");
        }
        getCompanies();
    }, []);

    const handleStatusChange = async (id, newStatus) => {
        try
        {
            Swal.fire({
                title: 'Loading...',
                text: "Changing company's status to "+ newStatus +".",
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            })
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://amtasksapi.somee.com/Company/UpdateStatus?companyStr='+ encodeURIComponent(id) +'&status=' + newStatus,
                headers: { 
                    'Authorization': localStorage.getItem("token")
                }
            }
            const response = await axios.request(config);
            if(response.success)
            {
                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: 'Updation Complete',
                    text: response.message,
                }).then(function(){
                    getCompanies()
                });
            }
            else
            {
                Swal.close();
                Swal.fire({
                    icon: 'success',
                    title: 'Updation Complete',
                    text: response.message,
                }).then(function(){
                    getCompanies()
                });
            }
        }
        catch(error)
        {
            Swal.close();
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong while fetching data!',
            });
        }
    };

    return (
        <>
            <SideBar style={tog} active="users" />
            <div id="right-panel" className="right-panel">
                <Header onIconClick={handleIconClick} />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="box-title">Companies </h4>
                                    </div>
                                    <div className="card-body">
                                        <CustomTable data={data} columns={columns} searchColumn="name" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
}