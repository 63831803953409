import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {isAuthenticated} from "../Functions/Authenticate";
import SideBar from "./Sub-Components/SideBar";
import Header from "./Sub-Components/Header";
import Footer from "./Sub-Components/Footer";
import CustomTable from "./Sub-Components/CustomTable";
import Swal from "sweetalert2";
import axios from "axios";
import { resolve } from "chart.js/helpers";
import { error } from "jquery";

export default function Dashboard()
{
    const  nav = useNavigate();
    const [tog, setTog] = useState({"display":"block"});

    const [dashData, setDashData] = useState({companies : 0, projects : 0, tasks : 0, clients : 0, visitors : {thisMonth : 0, percentageChange : 0}, uniqueVisitors : {thisMonth : 0, percentageChange : 0}})

    const [data, setData] = useState([])

    const getDashboardData = async() =>{
        try
        {
            Swal.fire({
                title: 'Loading...',
                text: 'Fetching company data.',
                allowOutsideClick: false,
                didOpen: () => {
                    Swal.showLoading();
                }
            })
            let config = {
                method: 'get',
                maxBodyLength: Infinity,
                url: 'https://amtasksapi.somee.com/Admin/Dashboard',
                headers: { 
                  'Authorization': localStorage.getItem("token")
                }
            }
            let response = await axios.request(config);
            if(response.data.success)
            {
                Swal.close();
                setDashData(
                    {
                        companies : response.data.companies, 
                        projects : response.data.projects, 
                        tasks : response.data.tasks, 
                        clients : response.data.clients, 
                        visitors : {
                            thisMonth : response.data.visits.currentMonth, 
                            percentageChange : response.data.visits.percentageChange
                        },
                        uniqueVisitors : {
                            thisMonth : response.data.uniqueVisits.currentMonth,
                            percentageChange : response.data.uniqueVisits.percentageChange
                        }
                    }
                )

                let trafficArr = []
                response.data.traffic["$values"].map(traffic => {
                    trafficArr.push({
                        id : traffic["$id"],
                        name : ((traffic.month == 1)?"January ":(traffic.month == 2)?"February":(traffic.month == 3)?"March":(traffic.month == 4)?"April":(traffic.month == 5)?"May":(traffic.month == 6)?"June":(traffic.month == 7)?"July":(traffic.month == 8)?"August":(traffic.month == 9)?"September":(traffic.month == 10)?"October":(traffic.month == 11)?"November":"December") + " - " + traffic.year,
                        trafficCount: traffic.count,
                        usersRegistered : traffic.userRegistered,
                        projectsStarted : traffic.projectCreated,
                        taskStarted : traffic.taskCreated
                    })
                })
                setData(trafficArr)
            }
            else
            {
                Swal.close();
                Swal.fire({
                    icon: "error",
                    title: 'Oops...',
                    text: 'Something went wrong while fetching data!',
                })
            }
        }
        catch(error)
        {
            console.log(error)
            Swal.close()
            Swal.fire({
                icon: "error",
                title: 'Oops...',
                text: 'Something went wrong while fetching data!',
            })
        }
    }

    const handleIconClick = () =>{
        let width = window.innerWidth;
        if (width < 1024) {
            if(tog.display === "block")
            {
                setTog({...tog,"display": "none"})
            }
            else
            {
                setTog({...tog,"display": "block"})
            }
        } else {
            document.body.classList.item("open") ? document.body.classList.remove("open") : document.body.classList.add("open")
        }
    }

    useEffect(()=>{
        if(!isAuthenticated())
        {
            nav("/");
        }

        getDashboardData()
    },[]);

    const columns = [
        {
            name : "Month",
            selector : row => row.name,
            sortable : true
        },
        {
            name : "Traffic",
            selector : row => row.trafficCount,
            sortable : true
        },
        {
            name : "Users Registered",
            selector : row => row.usersRegistered,
            sortable : true
        },
        {
            name : "Projects Started",
            selector : row => row.projectsStarted,
            sortable : true
        },
        {
            name : "Task Started",
            selector : row => row.taskStarted,
            sortable : true
        }
    ]

    return(
        <>
            <SideBar style={tog} active="dashboard" />
            <div id="right-panel" className="right-panel">
                <Header onIconClick={handleIconClick} />
                <div className="content">
                    <div className="animated fadeIn">
                        <div className="row">
                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-1">
                                                <i className="pe-7s-cash"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text"><span className="count">{dashData.companies}</span></div>
                                                    <div className="stat-heading">Companies</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-3">
                                                <i className="pe-7s-browser"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text"><span className="count">{dashData.projects}</span></div>
                                                    <div className="stat-heading">Projects</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-2">
                                                <i className="pe-7s-cart"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text"><span className="count">{dashData.tasks}</span></div>
                                                    <div className="stat-heading">Tasks</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-3 col-md-6">
                                <div className="card">
                                    <div className="card-body">
                                        <div className="stat-widget-five">
                                            <div className="stat-icon dib flat-color-4">
                                                <i className="pe-7s-users"></i>
                                            </div>
                                            <div className="stat-content">
                                                <div className="text-left dib">
                                                    <div className="stat-text"><span className="count">{dashData.clients}</span></div>
                                                    <div className="stat-heading">Clients</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-body">
                                        <h4 className="box-title">Traffic </h4>
                                    </div>
                                    <div className="row">
                                        <div className="col-lg-8">
                                            <div className="card-body">
                                                {/* Datatable Start */}
                                                <CustomTable data={data} columns={columns} searchColumn="name" />
                                                {/* Datatable End */}
                                            </div>
                                        </div>
                                        <div className="col-lg-4">
                                            <div className="card-body">
                                                <div className="progress-box progress-1">
                                                    <h4 className="por-title">Visits</h4>
                                                    <div className="por-txt">{dashData.visitors.thisMonth} Users ({dashData.visitors.percentageChange}%)</div>
                                                    <div className="progress mb-2" style={{"height": "5px"}}>
                                                        <div className={(dashData.visitors.percentageChange >= 0)?"progress-bar bg-flat-color-1":"progress-bar bg-flat-color-4"} role="progressbar" style={{"width": Math.abs(dashData.visitors.percentageChange) + "%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="progress-box progress-2">
                                                    <h4 className="por-title">Unique Visitors</h4>
                                                    <div className="por-txt">{dashData.uniqueVisitors.thisMonth} Users ({dashData.uniqueVisitors.percentageChange}%)</div>
                                                    <div className="progress mb-2" style={{"height": "5px"}}>
                                                        <div className={(dashData.uniqueVisitors.percentageChange >= 0)?"progress-bar bg-flat-color-3":"progress-bar bg-flat-color-4"} role="progressbar" style={{"width": Math.abs(dashData.uniqueVisitors.percentageChange) + "%"}} aria-valuenow="60" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                {/* <div className="progress-box progress-2">
                                                    <h4 className="por-title">Bounce Rate</h4>
                                                    <div className="por-txt">3,220 Users (24%)</div>
                                                    <div className="progress mb-2" style={{"height": "5px"}}>
                                                        <div className="progress-bar bg-flat-color-2" role="progressbar" style={{"width": "24%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div> */}
                                                {/* <div className="progress-box progress-2">
                                                    <h4 className="por-title">Targeted  Visitors</h4>
                                                    <div className="por-txt">99,658 Users (90%)</div>
                                                    <div className="progress mb-2" style={{"height": "5px"}}>
                                                        <div className="progress-bar bg-flat-color-4" role="progressbar" style={{"width": "90%"}} aria-valuenow="90" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    )
}