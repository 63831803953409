import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import {store} from "./Redux/store";
import Logout from "./Components/Logout";
import Users from "./Components/Users";
import Profile from "./Components/Profile";

export default function App()
{
  return(
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/users" element={<Users />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  )
}