import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import {isAuthenticated} from "../Functions/Authenticate";

export default function Login()
{
    const dispatch = useDispatch();
    const nav = useNavigate();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    function emailChange(e)
    {
        setEmail(e.target.value);
    }
    function passwordChange(e)
    {
        setPassword(e.target.value);
    }

    function loginFormSubmit(e)
    {
        e.preventDefault();

        dispatch({"type" : "LOGIN", "payload" : {"name" : email, "token" : password}});
        nav("/dashboard");
    }

    useEffect(()=>{
        if(isAuthenticated())
        {
            nav("/dashboard");
        }
        else
        {
            let urlParams = new URLSearchParams(window.location.search)
            if(urlParams.get("token") != null && urlParams.get("token") != "")
            {
                dispatch({"type" : "LOGIN", "payload" : {"name" : urlParams.get("name"), "token" : urlParams.get("token")}})
                
                nav("/dashboard")  
            }
            else
            {
                window.location.replace("https://amtasks.somee.com/")
            }
        }
    }, [])

    return(
        <div className="sufee-login d-flex align-content-center flex-wrap">
            <div className="container">
                <div className="login-content">
                    <div className="login-logo">
                        <Link to="/">
                            <img className="align-content" src="/AM-task-logo.ico" alt="" />
                        </Link>
                    </div>
                    <div className="login-form">
                        <form onSubmit={(e)=>loginFormSubmit(e)}>
                            <div className="form-group">
                                <label>Email address</label>
                                <input required={true} type="email" className="form-control" value={email} onChange={(e)=>{emailChange(e)}} placeholder="Email" />
                                <span id="emailErro" className="text-danger" style={{"fontSize" : "smaller"}}></span>
                            </div>
                            <div className="form-group">
                                <label>Password</label>
                                <input required={true} minLength={5} type="password" className="form-control" value={password} onChange={(e)=>passwordChange(e)} placeholder="Password" />
                            </div>
                            <button type="submit" style={{"backgroundColor": "#ac26ab", "color" : "white"}} className="btn btn-flat m-b-30 m-t-30">Sign in</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}