import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
export default function CustomTable(props)
{
    const columns = props.columns;
    const data = props.data;

    const [records, setRecords] = useState(data)

    useEffect(() => {
        setRecords(props.data);
    }, [props.data]);

    function handleFilter(e) {
        let filteredData;
        if(e.target.value != null && e.target.value != "")
        {
            filteredData = records.filter((item)=>{
                return item[props.searchColumn].toString().toLowerCase().includes(e.target.value.toLowerCase())
            })
        }
        else
        {
            filteredData = data;
        }
        setRecords(filteredData);
    }

    console.log(records);

    return(
        <>
            <div className="col-sm-4"><input className="form-control" type="text" name="search" placeholder="Search...." onChange={handleFilter} /></div>
            <DataTable 
                columns={columns}
                data={records}
                pagination
                fixedHeader
            ></DataTable>
        </>
    );
}