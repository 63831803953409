export function isAuthenticated()
{
    if(localStorage.getItem("token") == null || localStorage.getItem("token") == "")
    {
        return  false;
    }
    else
    {
        return true;
    }
}